<template>
	<div>
		<div class="homeLeftMenu">
			<div class="homeLeftMenuItem" v-for="(item, index) in leftMenu" :key="index" @click="$goto(item.path)">
				<img style="width:30px" :src="item.img" alt="" />
				<p style="width:40px;text-align:center;margin-top:10px">{{ item.title }}</p>
			</div>
		</div>
		<TitleMore :title="['会长信箱']" :isMore="false"></TitleMore>
		<div class="emailContent">
			<div class="emailLeft">
				<p style="font-size: 20px;font-weight: bold;color: #555454;line-height: 48px;margin-top:180px"></p>
				<!-- <TitleLine width="250px"></TitleLine> -->
				<el-form label-width="40px" label-position="right" class="donateForm">
					<el-form-item label="关键字" style="width: 100%;">
						<el-input style="width:85%" v-model="search_LIKE_keyWord" placeholder="请输入关键字"></el-input>
					</el-form-item>
					<el-form-item label="姓名" style="width:100%">
						<el-input style="width:85%" v-model="search_LIKE_username" placeholder="请输入姓名"></el-input>
					</el-form-item>
					<el-form-item label="时间" style="width:100%">
						<el-date-picker
							style="width:85%"
							v-model="dataVal"
							type="daterange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item label="主题" style="width:100%">
						<el-input style="width:85%" v-model="search_LIKE_title" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item label="电话号码" style="width:100%">
						<el-input style="width:85%" v-model="search_LIKE_tel" placeholder="请输入"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="submitBtn" style="width: 100px !important;" @click="onSearch">搜索</el-button>
						<el-button class="submitBtn" style="width: 100px !important;" @click="onSet">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="emailRight">
				<div class="emailHint">
					<img style="margin-left:10px" src="../../assets/img/hint.png" alt="" />
					<span style="font-size: 16px;font-weight: bold;color: #333333;line-height: 24px;margin-left:10px">
						温馨提示
					</span>
					<span style="font-size: 12px;color: #7B7A7A;margin-left:20px">
						会长信箱主要受理咨询建议类的来信来函
					</span>
				</div>
				<el-form v-if="!isSearch && !isShow" style="width:100%" :inline="true" label-width="80px" label-position="right" :model="formInline" class="donateForm">
					<el-row>
						<el-col :span="12">
							<el-form-item label="类别">
								<el-select v-model="formInline.typeId" placeholder="请选择" style="width:100%">
									<el-option v-for="item in typeList" :label="item.name" :value="item.id" :key="item.id"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="姓名" style="width:100%"><el-input style="width:100%" v-model="formInline.username" placeholder="请输入"></el-input></el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="固定电话"><el-input style="width:100%" v-model="formInline.mobile" placeholder="请输入"></el-input></el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="手机号码"><el-input style="width:100%" v-model="formInline.phone" placeholder="请输入"></el-input></el-form-item>
						</el-col>

						<el-col :span="12">
							<el-form-item label="电子邮箱"><el-input style="width:100%" v-model="formInline.email" placeholder="请输入"></el-input></el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="qq"><el-input style="width:100%" v-model="formInline.qq" placeholder="请输入"></el-input></el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="主题"><el-input style="width:100%" v-model="formInline.title" placeholder="请输入"></el-input></el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item class="textContent" label="信件内容" style="width:80%">
								<el-input type="textarea" rows="15" style="margin-left:80px;margin-top:-30px" v-model="formInline.text" placeholder="请输入"></el-input>
							</el-form-item>
						</el-col>
						<el-form-item><el-button class="submitBtn msgBtn" @click="onSubmit">提交</el-button></el-form-item>
					</el-row>
				</el-form>
				<!-- <el-table v-if="!isShow && isSearch" :data="tableData" stripe style="width: 100%">
					<el-table-column style="background:red" prop="title" label="主题"></el-table-column>
					<el-table-column prop="typeName" label="类别" width="50"></el-table-column>
					<el-table-column prop="username" label="姓名"></el-table-column>
					<el-table-column prop="creationDate" label="时间"></el-table-column>
					<el-table-column prop="text" label="内容">
						<template slot-scope="scope">
							<p>{{ scope.row.text.slice(0, 12) + '...' }}</p>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
						</template>
					</el-table-column>
				</el-table> -->
				<div style="width: 100%;box-sizing: border-box;padding: 0 20px;" v-if="!isShow && isSearch">
					<div style="display: flex;align-items: center;width: 100%;border-bottom: 2px solid #333333;line-height: 50px;">
						<span style="font-size: 28px;font-weight: bold;margin-right: 20px;">来信回复列表</span>
						<p class="backBtn" style="margin: 0;" @click="sendMsg">返回</p>
					</div>
					<div style="width: 100%;margin: 20px 0 40px 0;">
						<div style="width: 100%;box-sizing: border-box;padding: 20px 0;border-bottom: 1px solid #6c6c6c;" v-for="(item,index) of tableData">
							<div style="font-weight: bold;">{{item.username}}</div>
							<div style="margin-top: 10px;display: flex;align-items: center;">
								<img src="../../assets/images/问号.png" style="margin-right: 20px;width: 25px;height: 25px;">
								<div style="display: flex;flex-wrap: wrap;font-size: 14px;">
									<div style="margin-bottom: 10px;">
										{{item.text}}
									</div>
								</div>
							</div>
							<div style="display: flex;align-items: center;">
								<img src="../../assets/images/短信.png" style="margin-right: 20px;width: 25px;height: 25px;">
								<div style="font-size: 14px;">{{item.replyText}}</div>
							</div>
						</div>
					</div>
					<div class="fenye">
						<el-pagination
							:current-page="page"
							:page-size="size"
							layout="total,  prev, pager, next, jumper"
							:total="total"
							@current-change="handleCurrentChange" />
					</div>
				</div>
				
				<!-- <div class="detail" v-if="!isSearch && isShow">
					<p class="backBtn" @click="backList">返回列表</p>
					<p style="font-size: 24px;font-weight: bold;color: #555454;line-height: 48px;text-align:center">
						{{ detailData.title }}
					</p>
					<TitleLine width="400px"></TitleLine>
					<div class="introInfo">
						<span>发件人：{{ detailData.username || '暂无' }}</span>
						<span>类别：{{ detailData.typeName || '暂无' }}</span>
						<span>时间：{{ detailData.creationDate || '暂无' }}</span>
						<span>电子邮箱：{{ detailData.email || '暂无' }}</span>
						<span>手机号码：{{ detailData.phone || '暂无' }}</span>
						<span>固定电话：{{ detailData.mobile || '暂无' }}</span>
						<span>qq：{{ detailData.qq || '暂无' }}</span>
					</div>
					<div class="detailContent" v-html="detailData.text"></div>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import TitleMore from '@components/TitleMore';
import TitleLine from '@components/TitleLine';
import apiUrls from '@api';
export default {
	data() {
		return {
			formInline: {
				title: '',
				text: '',
				username: '',
				phone: '',
				mobile: '',
				email: '',
				qq: '',
				typeId: ''
			},
			typeList: [],
			
			page: 1,
			size: 3,
			total: 0,
			search_LIKE_keyWord: '',
			search_LIKE_username: '',
			dataVal: '',
			search_LIKE_title: '',
			search_LIKE_tel: '',
			isSearch: false,
			isShow: false,
			tableData: [],
			
			detailData: {},
			leftMenu: [
				{
					img: require('../../assets/img/help.png'),
					title: '我要求助',
					path: '/help'
				},
				{
					img: require('../../assets/img/idonate.png'),
					title: '我要捐赠',
					path: '/idonate'
				},
				{
					img: require('../../assets/img/complaint.png'),
					title: '我要参与',
					path: '/complain'
				},
				{
					img: require('../../assets/img/bossemail.png'),
					title: '会长信箱',
					path: '/email'
				}
			]
		};
	},

	components: {
		TitleMore,
		TitleLine
	},

	mounted() {
		this.getMsgType();
	},

	methods: {
		onSubmit() {
			this.isSearch = false;
			this.isShow = false;
			apiUrls.saveMsg(this.formInline).then(res => {
				res.rCode == 0 &&
					this.$message({
						message: '提交成功',
						type: 'success'
					});
			});
		},
		getMsgType() {
			apiUrls.msgType().then(res => {
				this.typeList = res.results;
			});
		},
		onSearch() {
			this.isSearch = true;
			this.isShow = false;
			apiUrls
				.msgList({
					search_LIKE_title: this.search_LIKE_title,
					search_LIKE_username: this.search_LIKE_username,
					search_GTE_creationDate_Date: this.dataVal[0],
					search_LTE_creationDate_Date: this.dataVal[1],
					search_LIKE_phone: this.search_LIKE_tel,
					keyWord: this.search_LIKE_keyWord,
					page: this.page - 1,
					size: this.size
				})
				.then(res => {
					this.tableData = res.results.data;
					this.total = res.results.pager.totalElements;
				});
		},
		onSet() {
			this.search_LIKE_keyWord = ''
			this.search_LIKE_username = ''
			this.dataVal = ''
			this.search_LIKE_title = ''
			this.search_LIKE_tel = ''
		},
		handleCurrentChange(val) {
			this.page = val;
			this.onSearch();
		},
		handleClick(data) {
			this.isSearch = false;
			this.isShow = true;
			this.detailData = data;
		},
		backList() {
			this.isSearch = true;
			this.isShow = false;
		},
		sendMsg() {
			this.isSearch = false;
			this.isShow = false;
		}
	}
};
</script>
<style lang="less" scoped>
.emailContent {
	width: 1200px;
	margin: 20px auto;
	display: flex;
	justify-content: space-between;
	height: 840px;
	.emailLeft {
		width: 320px;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 0px 5px 5px #e9e9e9;
		background: url('../../assets/img/emailLeftBg.png');
		/deep/ .el-form-item__label {
			width: 70px !important;
		}
		.fenye {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			/deep/ .el-pagination {
				margin-top: 20px !important;
			}
		}
		.donateForm {
			width: 90%;
			margin: 40px;
		}
	}
	.emailRight {
		width: 840px;
		height: 100%;
		box-shadow: 0px 0px 5px 5px #e9e9e9;
		background-color: #ffffff;
		.emailHint {
			display: flex;
			align-items: center;
			width: 95%;
			height: 33px;
			margin: 40px auto;
			background: #f9f4f4;
		}
		.introInfo {
			width: 400px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin: 0px auto;
			span {
				display: inline-block;
				width: 40%;
				font-size: 14px;
				margin-top: 10px;
			}
		}
		.detailContent {
			line-height: 30px;
			padding: 20px;
		}
	}
	/deep/ .el-form--inline .el-form-item {
		width: 100%;
		.el-form-item__content {
			width: calc(100% - 120px);
			.el-textarea {
				margin: 0px !important;
			}
		}
	}
}
.textContent {
	/deep/ .el-form-item__content {
		width: 100%;
	}
}
.msgBtn {
	width: 200px;
	margin-left: 40%;
	// margin: 0 auto;
}
.backBtn {
	width: 100px;
	height: 30px;
	background: linear-gradient(270deg, #ff7b7f, #d1292e) !important;
	border-radius: 4px !important;
	font-size: 14px !important;
	color: #ffffff !important;
	margin-left: 20px;
	text-align: center;
	line-height: 30px;
	cursor: pointer;
}
.homeLeftMenu {
	position: fixed;
	top: 40%;
	left: 0px;
	border-radius: 0px 24px 24px 0px;
	background: #ffffff;
	box-shadow: 0px 6px 18px 0px rgba(14, 14, 14, 0.27);
	padding: 10px;
	z-index: 100;
	.homeLeftMenuItem {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: 30px 0px;
		cursor: pointer;
	}
	.homeLeftMenuItem:first-child {
		margin-top: 10px;
	}
	.homeLeftMenuItem:last-child {
		margin-bottom: 10px;
	}
}
</style>
